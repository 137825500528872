import React, { useContext, ReactNode } from "react";
import { Navigate, useParams } from "react-router-dom";
import { TranslationProvider } from "../providers/TranslationProvider";
import { AppContext } from "../context/AppProvider";
import { SALESPERSON_TYPE, WORKER_TYPE } from "../utils/constants";
import i18next from "../pages/Reminders/translations/i18n";

interface IRemindersRouteProps {
  children: ReactNode;
}

export const RemindersRoute: React.FC<IRemindersRouteProps> = ({ children }) => {
  const { currentUser } = useContext(AppContext);

  const { userId } = useParams();

  const isOwnReminders = userId === currentUser.id.toString();

  if ((currentUser.type === SALESPERSON_TYPE && !isOwnReminders)
      || currentUser.type === WORKER_TYPE) {
    return <Navigate to="/" />;
  }

  return <TranslationProvider i18n={i18next}>{children}</TranslationProvider>;
};

export default RemindersRoute;
