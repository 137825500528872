import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useAdminStore } from "../../../hooks/useAdminStore";

export const LeadFormDetailsBreadcrumbs: React.FC = observer(() => {
  const params = useParams();
  const { t } = useTranslation(["default"]);
  const { leadFormId } = params;
  const adminStore = useAdminStore();
  const { leadFormsStore } = adminStore;

  const leadForm = leadFormsStore.findLeadForm(leadFormId as string);

  if (!leadForm) {
    return <span>{t("breadCrumb.leadFormDefault")}</span>;
  }

  return (
    <span>{leadForm.attributes.title}</span>
  );
});

export default LeadFormDetailsBreadcrumbs;
