import React, { ReactNode } from "react";
import { TranslationProvider } from "../providers/TranslationProvider";
import i18next from "../pages/Oauth/translations/i18n";

export interface IOauthRouteProps {
  children: ReactNode;
}

export const OauthRoute: React.FC<IOauthRouteProps> = ({ children }) => (
  <TranslationProvider i18n={i18next}>
    {children}
  </TranslationProvider>
);

export default OauthRoute;
