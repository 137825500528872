import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";
import { ILeadForm, IContent } from "../../types/ILeadForm";

export interface ILeadFormPayload {
  active?: boolean;
  description?: string;
  domain?: string;
  redirect_to?: string;
  style_string?: string;
  title?: string;
  content?: IContent[];
}

export interface ILeadFormsResponse {
  data: ILeadForm[];
}

const CAMPAIGN_LEAD_FORMS_ENDPOINT = (campaignId: string | number): string => endpoint(`app/v1.0/campaigns/${campaignId}/lead_forms`);

const CAMPAIGN_LEAD_FORM_ENDPOINT = (campaignId: string | number, leadFormId: string | number): string => endpoint(`app/v1.0/campaigns/${campaignId}/lead_forms/${leadFormId}`);

export const getLeadForms = async (campaignId: string | number): Promise<ILeadFormsResponse> => {
  const response = await axiosService.axiosGet(
    CAMPAIGN_LEAD_FORMS_ENDPOINT(campaignId),
  );

  return response.data;
};

export const updateLeadForm = async (
  campaignId: string | number,
  leadFormId: string | number,
  payload: ILeadFormPayload,
) => {
  const response = await axiosService.axiosPut(
    CAMPAIGN_LEAD_FORM_ENDPOINT(campaignId, leadFormId),
    {
      lead_form: payload,
    },
  );

  return response.data;
};

export const postLeadForm = async (campaignId: string | number, payload: ILeadFormPayload) => {
  const response = await axiosService.axiosPost(
    CAMPAIGN_LEAD_FORMS_ENDPOINT(campaignId),
    {
      lead_form: payload,
    },
  );

  return response.data;
};
