import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
} from "mobx";
import * as arrayUtils from "../utils/arrays";
import * as campaignUsersService from "../requests/campaigns/users";
import * as usersService from "../requests/users";

export class UsersStore {
  rootStore;

  @observable allUsers = [];

  @observable isLoading = true;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    onBecomeObserved(this, "allUsers", this.lazyLoadUsers.bind(this));
  }

  lazyLoadUsers() {
    const { campaignId } = this.rootStore;
    this.retrieveUsersAsync(campaignId, { include_disabled: true });
  }

  @computed get users() {
    return this.allUsers.filter((user) => !user.attributes.pmAccount && !user.attributes.internalAccount);
  }

  @computed get activeUsers() {
    return this.users.filter((user) => !user.attributes.disabled);
  }

  @computed get nonInternalUsers() {
    return this.allUsers.filter((user) => !user.attributes.internalAccount || user.attributes.pmAccount);
  }

  @computed get activeNonInternalUsers() {
    return this.nonInternalUsers.filter((user) => !user.disabled);
  }

  @computed get salespersons() {
    const salespersons = this.users.filter(
      (user) => user.attributes.type === "Salesperson"
    );
    return salespersons;
  }

  @computed get managers() {
    const managers = this.users.filter(
      (user) => user.attributes.type === "Manager"
    );
    return managers;
  }

  @computed get workers() {
    const workers = this.users.filter(
      (user) => user.attributes.type === "Worker"
    );
    return workers;
  }

  @computed get activeManagers() {
    const managers = this.managers.filter((user) => !user.attributes.disabled)
    return managers;
  }

  @computed get wingmatePms() {
    return this.allUsers.filter((user) => user.attributes.pmAccount);
  }

  @computed get owners() {
    return this.getOwners(this.allUsers.filter((user) => !user.attributes.internalAccount || user.attributes.pmAccount));
  }

  @computed get activeOwners() {
    return this.owners.filter((user) => !user.attributes.disabled);
  }

  getOwners(users) {
    return users.filter(
      (user) =>
        user.attributes.type === "Salesperson" ||
        user.attributes.type === "Manager"
    ).sort((a, b) => {
      if (!a.attributes.pmAccount && b.attributes.pmAccount) {
        return -1;
      }
      if (a.attributes.pmAccount && !b.attributes.pmAccount) {
        return 1;
      }
      return 0;
    });
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setAllUsers(allUsers) {
    this.allUsers = allUsers;
  }

  findUser(userId) {
    return this.allUsers.find(
      (user) => parseInt(user.id, 10) === parseInt(userId, 10)
    );
  }

  @action
  modifyUserInCache(userId, newUser) {
    const allUsers = [...this.allUsers];

    const userIndex = allUsers.findIndex((user) => user.id === userId);

    if (userIndex !== -1) {
      allUsers[userIndex] = newUser;

      this.setAllUsers(allUsers);
    }
  }

  @action
  setCachedUsers(newUsers) {
    const allUsers = [...newUsers, ...this.allUsers];

    const allUsersUnique = arrayUtils.uniqueOnId(allUsers, "id");

    this.setAllUsers(allUsersUnique);
  }

  @action
  async retrieveUsersAsync(campaignId, params = {}) {
    this.setIsLoading(true);

    try {
      const allUsers = await campaignUsersService.getCampaignUsers(
        campaignId,
        params
      );

      this.setAllUsers(allUsers);
      this.setIsLoading(false);
      return allUsers;
    } catch {
      this.setIsLoading(false);
      return;
    }
  }

  @action
  async retrieveUsersSettingsAsync(userId) {
    this.setIsLoading(true);

    try {
      const user = await usersService.getUserSettings(userId);

      this.setCachedUsers([user]);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async updateUserAsync(userId, payload) {
    this.setIsLoading(true);
    const user = await usersService.updateUser(
      userId,
      payload
    );

    this.modifyUserInCache(userId, user);

    this.setIsLoading(false);

    return user;
  }
}

export default UsersStore;
