import { IError } from "./IError";
import { IUser } from "./IUser";

export type executionErrors = {
  lead_id: string;
  message: string | { data: IError[] };
};

export enum MassUpdateStates {
  CONFIGURING = "configuring",
  RUNNING = "running",
  COMPLETED = "completed",
  FAILED = "failed",
}

export enum MassUpdateSteps {
  CREATE = "create",
  SELECT_LEADS = "selectLeads",
  CONFIGURE = "configure",
  VERIFY = "verify",
}

export enum MassUpdateTypes {
  MASS_ASSIGN_LEAD = "MassAssignLead",
  MASS_CREATE_REMINDER = "MassCreateReminder",
  MASS_DELETE_LEAD = "MassDeleteLead",
  MASS_FLAG = "MassFlag",
  MASS_MOVE_LEAD = "MassMoveLead",
  MASS_UPDATE_LEAD = "MassUpdateLead",
}

export interface IMassUpdateAttributes {
  aasmState: MassUpdateStates;
  campaignId: string;
  configuration: Record<string, unknown>;
  createdBy: IUser;
  createdAt: string;
  executionErrors: executionErrors[];
  finishedAt?: string;
  id: number;
  leadIds: string[];
  performedOn: string;
  type: MassUpdateTypes;
  updatedAt?: string;
}

export interface IMassUpdate {
  id: string;
  attributes: IMassUpdateAttributes;
}
