import { createInstance } from "i18next";
import { initReactI18next } from "react-i18next";
import locales from "./index";
import commonLocales from "../../../common/translations/index";

const i18n = createInstance({
  fallbackLng: "en",
});

i18n.use(initReactI18next).init({
  returnNull: false,
  resources: {
    en: {
      default: locales.en,
      common: commonLocales.en,
    },
    es: {
      default: locales.es,
      common: commonLocales.es,
    },
    fr: {
      default: locales.fr,
      common: commonLocales.fr,
    },
    "pt-BR": {
      default: locales.ptBR,
      common: commonLocales.ptBR,
    },
  },
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (format === "currency" || format === "currency(currency: USD)") return `$${value}`;
      return value;
    },
  },
});

export default i18n;
