import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../context/AppProvider";

export const WorkerRoute: React.FC = () => {
  const { currentUser } = useContext(AppContext);

  return (
    <div data-testid="Worker">
      {currentUser.type === "Worker" ? (
        <Navigate to="/frontline" />
      ) : (
        <Navigate to="/dashboard" />
      )}
    </div>
  );
};

export default WorkerRoute;
