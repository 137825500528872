import React from "react";
import { Outlet } from "react-router-dom";
import { TranslationProvider } from "../providers/TranslationProvider";
import i18next from "../pages/LeadForms/translations/i18n";

export const LeadFormsRoute: React.FC = () => (
  <TranslationProvider i18n={i18next}>
    <Outlet />
  </TranslationProvider>
);

export default LeadFormsRoute;
