import {
  action,
  computed,
  get,
  makeAutoObservable,
  observable,
  runInAction,
  set,
} from "mobx";
import { sortByAscDate, sortByDescDate } from "../utils/commons";

const COLUMNS = ["overdue", "active", "completed"];

const PAGE_SIZE = 20;

const DEFAULT_META = {
  overdue: {
    page: {
      current: 0,
      next: 0,
      more: false,
    },
    count: 0,
    order: "desc",
  },
  active: {
    page: {
      current: 0,
      next: 0,
      more: false,
    },
    count: 0,
    order: "asc",
  },
  completed: {
    page: {
      current: 0,
      next: 0,
      more: false,
    },
    count: 0,
    order: "desc",
  },
};

export class RemindersIndexStore {
  rootStore;
  @observable selectedUserId;
  @observable remindersStore;
  @observable isLoading;
  @observable columnMeta = observable.object(DEFAULT_META);
  @observable cachedColumnCount = observable.object({
    overdue: null,
    active: null,
    completed: null,
  });

  constructor(rootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
    this.remindersStore = rootStore.remindersStore;
  }

  @computed get usersCachedReminders() {
    return this.remindersStore.reminders.filter(
      (reminder) => reminder.attributes.userId === this.selectedUserId
    );
  }

  @computed get overdueRemindersShown() {
    const overdueColumnnMeta = this.getColumnMeta("overdue");

    const overdueReminders = this.usersCachedReminders.filter(
      (reminder) => reminder.attributes.status === "overdue"
    );

    this.handleColumnCount(
      "overdue",
      overdueReminders.length,
      overdueColumnnMeta.count
    );

    const overdueRemindersSorted = this.sortReminders(
      overdueReminders,
      overdueColumnnMeta.order,
      "startsAt"
    );

    return overdueRemindersSorted.splice(
      0,
      PAGE_SIZE * overdueColumnnMeta.page.current
    );
  }

  @computed get overdueRemindersCount() {
    return this.getColumnMeta("overdue").count;
  }

  @computed get overdueRemindersPage() {
    return this.getColumnMeta("overdue").page;
  }

  @computed get overdueRemindersOrder() {
    return this.getColumnMeta("overdue").order;
  }

  @computed get activeRemindersShown() {
    const activeColumnMeta = this.getColumnMeta("active");

    const activeReminders = this.usersCachedReminders.filter(
      (reminder) => reminder.attributes.status === "active"
    );

    this.handleColumnCount(
      "active",
      activeReminders.length,
      activeColumnMeta.count
    );

    const activeRemindersSorted = this.sortReminders(
      activeReminders,
      activeColumnMeta.order,
      "startsAt"
    );

    return activeRemindersSorted.splice(
      0,
      PAGE_SIZE * activeColumnMeta.page.current
    );
  }

  @computed get activeRemindersCount() {
    return this.getColumnMeta("active").count;
  }

  @computed get activeRemindersPage() {
    return this.getColumnMeta("active").page;
  }

  @computed get activeRemindersOrder() {
    return this.getColumnMeta("active").order;
  }

  @computed get completedRemindersShown() {
    const completedColumnMeta = this.getColumnMeta("completed");

    const completedReminders = this.usersCachedReminders.filter(
      (reminder) => reminder.attributes.status === "completed"
    );

    this.handleColumnCount(
      "completed",
      completedReminders.length,
      completedColumnMeta.count
    );

    const completedRemindersSorted = this.sortReminders(
      completedReminders,
      completedColumnMeta.order,
      "completedAt"
    );

    return completedRemindersSorted.splice(
      0,
      PAGE_SIZE * completedColumnMeta.page.current
    );
  }

  @computed get completedRemindersCount() {
    return this.getColumnMeta("completed").count;
  }

  @computed get completedRemindersPage() {
    return this.getColumnMeta("completed").page;
  }

  @computed get completedRemindersOrder() {
    return this.getColumnMeta("completed").order;
  }

  @computed get usersTotalReminders() {
    return (
      this.overdueRemindersCount +
      this.activeRemindersCount +
      this.completedRemindersCount
    );
  }

  @action
  getColumnMeta(column) {
    return get(this.columnMeta, column);
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setSelectedUserId(selectedUserId) {
    this.selectedUserId = selectedUserId;
  }

  @action
  setColumnPage(column, newPage) {
    const columnMeta = this.getColumnMeta(column);
    columnMeta.page = newPage;

    this.setColumnMeta(column, columnMeta);
  }

  @action
  setColumnOrder(column, newOrder) {
    const columnMeta = this.getColumnMeta(column);
    columnMeta.order = newOrder;

    this.setColumnMeta(column, columnMeta);
  }

  @action
  setColumnCount(column, newCount) {
    const columnMeta = this.getColumnMeta(column);
    columnMeta.count = newCount;

    this.setColumnMeta(column, columnMeta);
  }

  @action
  setColumnMeta(status, meta) {
    const columnMeta = this.columnMeta;

    columnMeta[status].page = meta.page;
    columnMeta[status].count = meta.count;

    this.resetCachedColumnCount(status);

    set(this.columnMeta, columnMeta);
  }

  @action
  resetColumnMeta() {
    this.columnMeta = observable.object(DEFAULT_META);
  }

  @action
  resetCachedColumnCount(column) {
    this.updateCachedColumnCount(column, null);
  }

  sortReminders(reminders, sortOrder, sortBy) {
    if (sortOrder === "asc") {
      return reminders.sort((reminderA, reminderB) =>
        sortByAscDate(
          reminderA.attributes[sortBy],
          reminderB.attributes[sortBy]
        )
      );
    } else {
      return reminders.sort((reminderA, reminderB) =>
        sortByDescDate(
          reminderA.attributes[sortBy],
          reminderB.attributes[sortBy]
        )
      );
    }
  }

  @action
  handleColumnCount(column, cachedColumnCount, metaColumnCount) {
    runInAction(() => {
      if (!this.cachedColumnCount[column]) {
        this.updateCachedColumnCount(column, cachedColumnCount);
      } else {
        if (cachedColumnCount !== this.cachedColumnCount[column]) {
          const frontEndDifference =
            cachedColumnCount - this.cachedColumnCount[column];
          const trueCount = metaColumnCount + frontEndDifference;

          this.setColumnCount(column, trueCount);
          this.updateCachedColumnCount(column, cachedColumnCount);
        }
      }
    });
  }

  @action
  updateCachedColumnCount(column, newCount) {
    set(this.cachedColumnCount, column, newCount);
  }

  @action
  initializeColumns() {
    COLUMNS.map((status) => this.getRemindersForColumn(status));
  }

  @action
  async getRemindersForColumn(status) {
    this.setIsLoading(true);
    const columnMeta = this.getColumnMeta(status);

    const payload = {
      status: status,
      page: columnMeta.page.next,
      sort_order: columnMeta.order,
      campaign_id: this.rootStore.context.campaign.id,
      sort_by: "starts_at",
    };

    if (status === "completed") {
      payload.sort_by = "completed_at";
    }

    const response = await this.remindersStore.retrieveRemindersForUsersAsync(
      this.selectedUserId,
      payload
    );
    this.setColumnMeta(status, response.data.meta);

    this.setIsLoading(false);
  }
}
