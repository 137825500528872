export const TOP_PAGE_SPACING = 185;

export interface ITimeZone {
  id: string;
  label: string;
  value: string;
}

export const LOCALES = [
  {
    id: "en",
    label: "English",
    value: "en",
  },
  {
    id: "es",
    label: "Español",
    value: "es",
  },
  {
    id: "fr",
    label: "Français",
    value: "fr",
  },
  {
    id: "pt-BR",
    label: "Português (Brasil)",
    value: "pt-BR",
  },
];

export const TIME_ZONE_OPTIONS: ITimeZone[] = [
  {
    label: "(GMT-12:00) International Date Line West",
    value: "International Date Line West",
    id: "International Date Line West",
  },
  {
    label: "(GMT-11:00) American Samoa",
    value: "American Samoa",
    id: "American Samoa",
  },
  { label: "(GMT-11:00) Midway Island", value: "Midway Island", id: "Midway Island" },
  { label: "(GMT-10:00) Hawaii", value: "Hawaii", id: "Hawaii" },
  { label: "(GMT-09:00) Alaska", value: "Alaska", id: "Alaska" },
  {
    label: "(GMT-08:00) Pacific Time (US & Canada)",
    value: "Pacific Time (US & Canada)",
    id: "Pacific Time (US & Canada)",
  },
  { label: "(GMT-08:00) Tijuana", value: "Tijuana", id: "Tijuana" },
  { label: "(GMT-07:00) Arizona", value: "Arizona", id: "Arizona" },
  { label: "(GMT-07:00) Chihuahua", value: "Chihuahua", id: "Chihuahua" },
  { label: "(GMT-07:00) Mazatlan", value: "Mazatlan", id: "Mazatlan" },
  {
    label: "(GMT-07:00) Mountain Time (US & Canada)",
    value: "Mountain Time (US & Canada)",
    id: "Mountain Time (US & Canada)",
  },
  { label: "(GMT-06:00) Central America", value: "Central America", id: "Central America" },
  {
    label: "(GMT-06:00) Central Time (US & Canada)",
    value: "Central Time (US & Canada)",
    id: "Central Time (US & Canada)",
  },
  { label: "(GMT-06:00) Guadalajara", value: "Guadalajara", id: "Guadalajara" },
  { label: "(GMT-06:00) Mexico City", value: "Mexico City", id: "Mexico City" },
  { label: "(GMT-06:00) Monterrey", value: "Monterrey", id: "Monterrey" },
  { label: "(GMT-06:00) Saskatchewan", value: "Saskatchewan", id: "Saskatchewan" },
  { label: "(GMT-05:00) Bogota", value: "Bogota", id: "Bogota" },
  {
    label: "(GMT-05:00) Eastern Time (US & Canada)",
    value: "Eastern Time (US & Canada)",
    id: "Eastern Time (US & Canada)",
  },
  { label: "(GMT-05:00) Indiana (East)", value: "Indiana (East)", id: "Indiana (East)" },
  { label: "(GMT-05:00) Lima", value: "Lima", id: "Lima" },
  { label: "(GMT-05:00) Quito", value: "Quito", id: "Quito" },
  {
    label: "(GMT-04:00) Atlantic Time (Canada)",
    value: "Atlantic Time (Canada)",
    id: "Atlantic Time (Canada)",
  },
  { label: "(GMT-04:00) Caracas", value: "Caracas", id: "Caracas" },
  { label: "(GMT-04:00) Georgetown", value: "Georgetown", id: "Georgetown" },
  { label: "(GMT-04:00) La Paz", value: "La Paz", id: "La Paz" },
  { label: "(GMT-04:00) Puerto Rico", value: "Puerto Rico", id: "Puerto Rico" },
  { label: "(GMT-04:00) Santiago", value: "Santiago", id: "Santiago" },
  { label: "(GMT-03:30) Newfoundland", value: "Newfoundland", id: "Newfoundland" },
  { label: "(GMT-03:00) Brasilia", value: "Brasilia", id: "Brasilia" },
  { label: "(GMT-03:00) Buenos Aires", value: "Buenos Aires", id: "Buenos Aires" },
  { label: "(GMT-03:00) Greenland", value: "Greenland", id: "Greenland" },
  { label: "(GMT-03:00) Montevideo", value: "Montevideo", id: "Montevideo" },
  { label: "(GMT-02:00) Mid-Atlantic", value: "Mid-Atlantic", id: "Mid-Atlantic" },
  { label: "(GMT-01:00) Azores", value: "Azores", id: "Azores" },
  { label: "(GMT-01:00) Cape Verde Is.", value: "Cape Verde Is.", id: "Cape Verde Is." },
  { label: "(GMT+00:00) Edinburgh", value: "Edinburgh", id: "Edinburgh" },
  { label: "(GMT+00:00) Lisbon", value: "Lisbon", id: "Lisbon" },
  { label: "(GMT+00:00) London", value: "London", id: "London" },
  { label: "(GMT+00:00) Monrovia", value: "Monrovia", id: "Monrovia" },
  { label: "(GMT+00:00) UTC", value: "UTC", id: "UTC" },
  { label: "(GMT+01:00) Amsterdam", value: "Amsterdam", id: "Amsterdam" },
  { label: "(GMT+01:00) Belgrade", value: "Belgrade", id: "Belgrade" },
  { label: "(GMT+01:00) Berlin", value: "Berlin", id: "Berlin" },
  { label: "(GMT+01:00) Bern", value: "Bern", id: "Bern" },
  { label: "(GMT+01:00) Bratislava", value: "Bratislava", id: "Bratislava" },
  { label: "(GMT+01:00) Brussels", value: "Brussels", id: "Brussels" },
  { label: "(GMT+01:00) Budapest", value: "Budapest", id: "Budapest" },
  { label: "(GMT+01:00) Casablanca", value: "Casablanca", id: "Casablanca" },
  { label: "(GMT+01:00) Copenhagen", value: "Copenhagen", id: "Copenhagen" },
  { label: "(GMT+01:00) Dublin", value: "Dublin", id: "Dublin" },
  { label: "(GMT+01:00) Ljubljana", value: "Ljubljana", id: "Ljubljana" },
  { label: "(GMT+01:00) Madrid", value: "Madrid", id: "Madrid" },
  { label: "(GMT+01:00) Paris", value: "Paris", id: "Paris" },
  { label: "(GMT+01:00) Prague", value: "Prague", id: "Prague" },
  { label: "(GMT+01:00) Rome", value: "Rome", id: "Rome" },
  { label: "(GMT+01:00) Sarajevo", value: "Sarajevo", id: "Sarajevo" },
  { label: "(GMT+01:00) Skopje", value: "Skopje", id: "Skopje" },
  { label: "(GMT+01:00) Stockholm", value: "Stockholm", id: "Stockholm" },
  { label: "(GMT+01:00) Vienna", value: "Vienna", id: "Vienna" },
  { label: "(GMT+01:00) Warsaw", value: "Warsaw", id: "Warsaw" },
  { label: "(GMT+01:00) West Central Africa", value: "West Central Africa", id: "West Central Africa" },
  { label: "(GMT+01:00) Zagreb", value: "Zagreb", id: "Zagreb" },
  { label: "(GMT+01:00) Zurich", value: "Zurich", id: "Zurich" },
  { label: "(GMT+02:00) Athens", value: "Athens", id: "Athens" },
  { label: "(GMT+02:00) Bucharest", value: "Bucharest", id: "Bucharest" },
  { label: "(GMT+02:00) Cairo", value: "Cairo", id: "Cairo" },
  { label: "(GMT+02:00) Harare", value: "Harare", id: "Harare" },
  { label: "(GMT+02:00) Helsinki", value: "Helsinki", id: "Helsinki" },
  { label: "(GMT+02:00) Jerusalem", value: "Jerusalem", id: "Jerusalem" },
  { label: "(GMT+02:00) Kaliningrad", value: "Kaliningrad", id: "Kaliningrad" },
  { label: "(GMT+02:00) Kyiv", value: "Kyiv", id: "Kyiv" },
  { label: "(GMT+02:00) Pretoria", value: "Pretoria", id: "Pretoria" },
  { label: "(GMT+02:00) Riga", value: "Riga", id: "Riga" },
  { label: "(GMT+02:00) Sofia", value: "Sofia", id: "Sofia" },
  { label: "(GMT+02:00) Tallinn", value: "Tallinn", id: "Tallinn" },
  { label: "(GMT+02:00) Vilnius", value: "Vilnius", id: "Vilnius" },
  { label: "(GMT+03:00) Baghdad", value: "Baghdad", id: "Baghdad" },
  { label: "(GMT+03:00) Istanbul", value: "Istanbul", id: "Istanbul" },
  { label: "(GMT+03:00) Kuwait", value: "Kuwait", id: "Kuwait" },
  { label: "(GMT+03:00) Minsk", value: "Minsk", id: "Minsk" },
  { label: "(GMT+03:00) Moscow", value: "Moscow", id: "Moscow" },
  { label: "(GMT+03:00) Nairobi", value: "Nairobi", id: "Nairobi" },
  { label: "(GMT+03:00) Riyadh", value: "Riyadh", id: "Riyadh" },
  { label: "(GMT+03:00) St. Petersburg", value: "St. Petersburg", id: "St. Petersburg" },
  { label: "(GMT+03:00) Volgograd", value: "Volgograd", id: "Volgograd" },
  { label: "(GMT+03:30) Tehran", value: "Tehran", id: "Tehran" },
  { label: "(GMT+04:00) Abu Dhabi", value: "Abu Dhabi", id: "Abu Dhabi" },
  { label: "(GMT+04:00) Baku", value: "Baku", id: "Baku" },
  { label: "(GMT+04:00) Muscat", value: "Muscat", id: "Muscat" },
  { label: "(GMT+04:00) Samara", value: "Samara", id: "Samara" },
  { label: "(GMT+04:00) Tbilisi", value: "Tbilisi", id: "Tbilisi" },
  { label: "(GMT+04:00) Yerevan", value: "Yerevan", id: "Yerevan" },
  { label: "(GMT+04:30) Kabul", value: "Kabul", id: "Kabul" },
  { label: "(GMT+05:00) Ekaterinburg", value: "Ekaterinburg", id: "Ekaterinburg" },
  { label: "(GMT+05:00) Islamabad", value: "Islamabad", id: "Islamabad" },
  { label: "(GMT+05:00) Karachi", value: "Karachi", id: "Karachi" },
  { label: "(GMT+05:00) Tashkent", value: "Tashkent", id: "Tashkent" },
  { label: "(GMT+05:30) Chennai", value: "Chennai", id: "Chennai" },
  { label: "(GMT+05:30) Kolkata", value: "Kolkata", id: "Kolkata" },
  { label: "(GMT+05:30) Mumbai", value: "Mumbai", id: "Mumbai" },
  { label: "(GMT+05:30) New Delhi", value: "New Delhi", id: "New Delhi" },
  { label: "(GMT+05:30) Sri Jayawardenepura", value: "Sri Jayawardenepura", id: "Sri Jayawardenepura" },
  { label: "(GMT+05:45) Kathmandu", value: "Kathmandu", id: "Kathmandu" },
  { label: "(GMT+06:00) Almaty", value: "Almaty", id: "Almaty" },
  { label: "(GMT+06:00) Astana", value: "Astana", id: "Astana" },
  { label: "(GMT+06:00) Dhaka", value: "Dhaka", id: "Dhaka" },
  { label: "(GMT+06:00) Urumqi", value: "Urumqi", id: "Urumqi" },
  { label: "(GMT+06:30) Rangoon", value: "Rangoon", id: "Rangoon" },
  { label: "(GMT+07:00) Bangkok", value: "Bangkok", id: "Bangkok" },
  { label: "(GMT+07:00) Hanoi", value: "Hanoi", id: "Hanoi" },
  { label: "(GMT+07:00) Jakarta", value: "Jakarta", id: "Jakarta" },
  { label: "(GMT+07:00) Krasnoyarsk", value: "Krasnoyarsk", id: "Krasnoyarsk" },
  { label: "(GMT+07:00) Novosibirsk", value: "Novosibirsk", id: "Novosibirsk" },
  { label: "(GMT+08:00) Beijing", value: "Beijing", id: "Beijing" },
  { label: "(GMT+08:00) Chongqing", value: "Chongqing", id: "Chongqing" },
  { label: "(GMT+08:00) Hong Kong", value: "Hong Kong", id: "Hong Kong" },
  { label: "(GMT+08:00) Irkutsk", value: "Irkutsk", id: "Irkutsk" },
  { label: "(GMT+08:00) Kuala Lumpur", value: "Kuala Lumpur", id: "Kuala Lumpur" },
  { label: "(GMT+08:00) Perth", value: "Perth", id: "Perth" },
  { label: "(GMT+08:00) Singapore", value: "Singapore", id: "Singapore" },
  { label: "(GMT+08:00) Taipei", value: "Taipei", id: "Taipei" },
  { label: "(GMT+08:00) Ulaanbaatar", value: "Ulaanbaatar", id: "Ulaanbaatar" },
  { label: "(GMT+09:00) Osaka", value: "Osaka", id: "Osaka" },
  { label: "(GMT+09:00) Sapporo", value: "Sapporo", id: "Sapporo" },
  { label: "(GMT+09:00) Seoul", value: "Seoul", id: "Seoul" },
  { label: "(GMT+09:00) Tokyo", value: "Tokyo", id: "Tokyo" },
  { label: "(GMT+09:00) Yakutsk", value: "Yakutsk", id: "Yakutsk" },
  { label: "(GMT+09:30) Adelaide", value: "Adelaide", id: "Adelaide" },
  { label: "(GMT+09:30) Darwin", value: "Darwin", id: "Darwin" },
  { label: "(GMT+10:00) Brisbane", value: "Brisbane", id: "Brisbane" },
  { label: "(GMT+10:00) Canberra", value: "Canberra", id: "Canberra" },
  { label: "(GMT+10:00) Guam", value: "Guam", id: "Guam" },
  { label: "(GMT+10:00) Hobart", value: "Hobart", id: "Hobart" },
  { label: "(GMT+10:00) Melbourne", value: "Melbourne", id: "Melbourne" },
  { label: "(GMT+10:00) Port Moresby", value: "Port Moresby", id: "Port Moresby" },
  { label: "(GMT+10:00) Sydney", value: "Sydney", id: "Sydney" },
  { label: "(GMT+11:00) Bougainville Island", value: "Bougainville Island", id: "Bougainville Island" },
  { label: "(GMT+11:00) Solomon Is.", value: "Solomon Is.", id: "Solomon Is." },
  { label: "(GMT+11:00) Magadan", value: "Magadan", id: "Magadan" },
  { label: "(GMT+12:00) Fiji", value: "Fiji", id: "Fiji" },
  { label: "(GMT+12:00) Kamchatka", value: "Kamchatka", id: "Kamchatka" },
  { label: "(GMT+12:00) Marshall Is.", value: "Marshall Is.", id: "Marshall Is." },
  { label: "(GMT+12:00) Wellington", value: "Wellington", id: "Wellington" },
  { label: "(GMT+13:00) Nuku'alofa", value: "Nuku'alofa", id: "Nuku'alofa" },
  { label: "(GMT+13:00) Tokelau Is.", value: "Tokelau Is.", id: "Tokelau Is." },
];

export const MIME_TYPES = {
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  doc: "application/msword",
  pdf: "application/pdf",
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  gif: "image/gif",
  svg: "image/svg+xml",
  txt: "text/plain",
  csv: "text/csv",
  zip: "application/zip",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  mp4: "video/mp4",
  mp3: "audio/mpeg",
  wav: "audio/wav",
};

export const LEAD_TYPE = "Lead";
export const PRICE_FIELD_TYPE = "price";
export const PURCHASE_TYPE = "Purchase";
export const MANAGER_TYPE = "Manager";
export const SALESPERSON_TYPE = "Salesperson";
export const WORKER_TYPE = "Worker";
export const ENTER_KEY = "Enter";

export const REQUIRED_FIELD_TYPES = {
  field: "field",
  customField: "custom_field",
  contactField: "contact_field",
  contactCustomField: "contact_custom_field",
};

export const STATIC_TABLE_COLUMN_HEADERS = [
  "address",
  "amount",
  "creator",
  "description",
  "name",
  "owner",
  "status",
  "updatedAt",
];

export default {
  TIME_ZONE_OPTIONS,
  TOP_PAGE_SPACING,
};
