import {
  action, makeAutoObservable, observable,
} from "mobx";
import * as leadFormsSubmissionsService from "../requests/leadForms/submissions";
import { ISubmissionsIndexResponse, ISubmissionsIndexParams } from "../requests/leadForms/submissions";
import { uniqueOnId } from "../utils/arrays";
import {
  ISubmission,
} from "../types/ISubmission";
import {
  Page,
  PageManager,
} from "../types/Meta";

// TODO: Remove this mock type when AdminStore.js
// is converted to AdminStore.ts
export interface IMockAdminStore {
  campaignId: number;
}

export type CountManager = Record<string, number>;

export class SubmissionsStore {
  @observable adminStore: IMockAdminStore;

  @observable isLoading = false;

  @observable submissions: ISubmission[] = [];

  @observable pageManager: PageManager = {};

  @observable totalCountManager: CountManager = {};

  constructor(adminStore: IMockAdminStore) {
    makeAutoObservable(this);

    this.adminStore = adminStore;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setSubmissions(submissions: ISubmission[]) {
    this.submissions = submissions;
  }

  @action
  setPageManager(pageManager: PageManager) {
    this.pageManager = pageManager;
  }

  @action
  setTotalCountManager(totalCountManager: CountManager) {
    this.totalCountManager = totalCountManager;
  }

  @action
  updatePageForLeadForm(leadFormId: string, page: Page) {
    const newPageManager = { ...this.pageManager };
    newPageManager[leadFormId] = page;

    this.setPageManager(newPageManager);
  }

  @action
  getPageForLeadForm(leadFormId: string) {
    return this.pageManager[leadFormId];
  }

  @action
  getSubmissionsForLeadForm(leadFormId: string) {
    return this.submissions.filter(
      (submission: ISubmission) => submission.attributes.leadFormId.toString() === leadFormId,
    );
  }

  @action
  updateTotalCountForLeadForm(leadFormId: string, count: number) {
    const newCountManager = { ...this.totalCountManager };
    newCountManager[leadFormId] = count;

    this.setTotalCountManager(newCountManager);
  }

  @action
  addSubmissionsToCache(submissions: ISubmission[]) {
    const newSubmissions = [...submissions, ...this.submissions];
    const uniqueSubmissions = uniqueOnId(newSubmissions, "id");

    this.setSubmissions(uniqueSubmissions);
  }

  @action
  async getSubmissionsForLeadFormAsync(leadFormId: string, page?: number) {
    this.setIsLoading(true);

    let params: ISubmissionsIndexParams | undefined;

    if (page) {
      params = { page };
    }

    const response: ISubmissionsIndexResponse = await leadFormsSubmissionsService.getSubmissions(
      leadFormId,
      params,
    );

    this.updatePageForLeadForm(leadFormId, response.meta.page);
    this.updateTotalCountForLeadForm(leadFormId, response.meta.count);
    this.addSubmissionsToCache(response.data);

    this.setIsLoading(false);
  }
}
