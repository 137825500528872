import {
  action, computed, makeAutoObservable, observable,
} from "mobx";
import * as campaignLeadFormsService from "../requests/campaigns/leadForms";
import * as leadFormsService from "../requests/leadForms";
import { ILeadFormPayload } from "../requests/campaigns/leadForms";
import { ILeadForm } from "../types/ILeadForm";
import { ICampaign } from "../types/ICampaign";

// TODO: Remove this mock type when AdminStore.js
// is converted to AdminStore.ts
export interface IMockAdminStore {
  campaignId: number;
  campaign: ICampaign;
}

export class LeadFormsStore {
  @observable adminStore: IMockAdminStore;

  @observable campaignId: number;

  @observable leadForms: ILeadForm[] = [];

  @observable isLoading = false;

  constructor(adminStore: IMockAdminStore) {
    makeAutoObservable(this);
    this.adminStore = adminStore;
    this.campaignId = adminStore.campaignId;
  }

  @computed
  get activeLeadForms() {
    return this.leadForms.filter((form: ILeadForm) => form.attributes.active === true);
  }

  @computed
  get inactiveLeadForms() {
    return this.leadForms.filter((form: ILeadForm) => form.attributes.active === false);
  }

  @action
  setLeadForms(leadForms: ILeadForm[]) {
    this.leadForms = leadForms.sort((a, b) => a.attributes.title
      .toLowerCase()
      .localeCompare(b.attributes.title.toLowerCase()));
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @computed get isEmpty() {
    return this.leadForms.length === 0;
  }

  @action
  modifyLeadFormInCache(leadFormId: string, newLeadForm: ILeadForm) {
    const leadForms = [...this.leadForms];
    const leadFormIndex = leadForms.findIndex(
      (leadForm) => leadForm.attributes.id === parseInt(leadFormId, 10),
    );

    if (leadFormIndex !== -1) {
      leadForms[leadFormIndex] = newLeadForm;

      this.setLeadForms(leadForms);
    } else {
      this.setLeadForms([...leadForms, newLeadForm]);
    }
  }

  @action
  findLeadForm(leadFormId: string): ILeadForm | undefined {
    const form = this.leadForms.find(
      (leadForm) => leadForm.attributes.id === parseInt(leadFormId, 10),
    );

    return form;
  }

  @action
  removeLeadFormById(leadFormId: number) {
    const forms = this.leadForms.filter(
      (leadForm) => leadForm.attributes.id !== leadFormId,
    );

    this.setLeadForms(forms);
  }

  @action
  async getLeadFormAsync(leadFormId: string) {
    this.setIsLoading(true);
    const leadForm = await leadFormsService.getLeadForm(leadFormId);
    this.modifyLeadFormInCache(leadFormId, leadForm);
    this.setIsLoading(false);
  }

  @action
  async getLeadFormsAsync() {
    this.setIsLoading(true);
    const response = await campaignLeadFormsService.getLeadForms(this.campaignId);

    const leadForms = response.data;
    if (leadForms) {
      this.setLeadForms(leadForms);
    }

    this.setIsLoading(false);

    return leadForms;
  }

  @action
  async updateLeadFormAsync(leadFormId: string, payload: ILeadFormPayload): Promise<ILeadForm> {
    this.setIsLoading(true);

    try {
      const leadForm: ILeadForm = await leadFormsService.updateLeadForm(
        leadFormId,
        payload,
      );

      this.modifyLeadFormInCache(leadFormId, leadForm);
      this.setIsLoading(false);

      return leadForm;
    } catch (err: unknown) {
      this.setIsLoading(false);

      throw err;
    }
  }

  @action
  async postLeadFormAsync(payload: ILeadFormPayload) {
    this.setIsLoading(true);

    const leadForm = await campaignLeadFormsService.postLeadForm(
      this.campaignId,
      payload,
    );

    this.setLeadForms([...this.leadForms, leadForm]);

    this.setIsLoading(false);

    return leadForm;
  }

  @action
  async deleteLeadFormAsync(leadFormId: number) {
    this.setIsLoading(true);

    try {
      await leadFormsService.deleteLeadForm(
        leadFormId,
      );
    } catch (err: unknown) {
      this.setIsLoading(false);
      throw (err);
    }

    this.setIsLoading(false);
    this.removeLeadFormById(leadFormId);
  }
}
