import { axiosDelete, axiosGet, axiosPut } from "./axiosRequests";
import { endpoint } from "../utils/endpoint";
import { ILeadForm, IContent } from "../types/ILeadForm";

export interface ILeadFormUpdatePayload {
  active?: boolean;
  description?: string;
  domain?: string;
  redirect_to?: string;
  style_string?: string;
  title?: string;
  content?: IContent[];
}

const LEAD_FORM_ENDPOINT = (id: string | number) => endpoint(`app/v1.0/lead_forms/${id}`);

export const deleteLeadForm = async (leadFormId: number) => {
  const response = await axiosDelete(LEAD_FORM_ENDPOINT(leadFormId));

  return response.data;
};

export const getLeadForm = async (leadFormId: number | string): Promise<ILeadForm> => {
  const response = await axiosGet(LEAD_FORM_ENDPOINT(leadFormId));

  return response.data;
};

export const updateLeadForm = async (
  leadFormId: string | number,
  payload: ILeadFormUpdatePayload,
): Promise<ILeadForm> => {
  const response = await axiosPut(LEAD_FORM_ENDPOINT(leadFormId), {
    lead_form: payload,
  });

  return response.data;
};

export default { deleteLeadForm, getLeadForm };
