import axios from "axios";
import { pmServiceEndpoint } from "../utils/endpoint";

export async function getChases(authToken: string, leadId: string, userId: string) {
  const endpoint = pmServiceEndpoint(`leads/${leadId}/chases`);

  const response = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params: {
      userId,
    },
  });

  return response.data;
}

export async function createChase(authToken: string, leadId: string, userId: string) {
  const endpoint = pmServiceEndpoint("chases");

  const response = await axios.post(
    endpoint,
    {
      leadId,
      userId,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );

  return response.data;
}

export async function updateChase(
  authToken: string,
  chaseId: string,
  status: string,
  userId: string,
) {
  const endpoint = pmServiceEndpoint(`chases/${chaseId}`);

  const response = await axios.put(endpoint, {
    status,
    userId,
  }, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  return response.data;
}

export async function deleteChase(authToken: string, chaseId: string, userId: string) {
  const endpoint = pmServiceEndpoint(`chases/${chaseId}`);

  await axios.delete(endpoint, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params: {
      userId,
    },
  });
}
