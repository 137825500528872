import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useAdminStore } from "../../../../../hooks";
import { formatDateByMonthDayYear } from "../../../../../utils/dateTime";

export const EnterprisePayoutBreadcrumb = observer(() => {
  const { id } = useParams();

  const adminStore = useAdminStore();
  const { companyTransactionsStore } = adminStore;

  const transaction = companyTransactionsStore.getTransaction(id as string);

  if (!transaction) return null;

  return (
    <span data-testid="EnterprisePayoutBreadcrumb">
      {formatDateByMonthDayYear(transaction.attributes.updatedAt)}
    </span>
  );
});

export default EnterprisePayoutBreadcrumb;
