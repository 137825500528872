import { axiosGet } from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";
import { ISubmission } from "../../types/ISubmission";
import { Meta } from "../../types/Meta";

const LEAD_FORM_SUBMISSIONS_ENDPOINT = (leadFormId: string | number) => endpoint(`app/v1.0/lead_forms/${leadFormId}/submissions`);

export interface ISubmissionsIndexResponse {
  data: ISubmission[];
  meta: Meta;
}

export interface ISubmissionsIndexParams {
  page?: number;
}

export const getSubmissions = async (
  leadFormId: number | string,
  params?: ISubmissionsIndexParams,
): Promise<ISubmissionsIndexResponse> => {
  const response = await axiosGet(
    LEAD_FORM_SUBMISSIONS_ENDPOINT(leadFormId),
    params,
  );

  return response.data;
};

export default { getSubmissions };
