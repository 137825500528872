import React, { ReactNode } from "react";
import { TranslationProvider } from "../providers/TranslationProvider";
import i18next from "../pages/LeadFormDetails/translations/i18n";

export interface ILeadFormRouteProps {
  children: ReactNode;
}

export const LeadFormRoute: React.FC<ILeadFormRouteProps> = ({ children }) => (
  <TranslationProvider i18n={i18next}>
    {children}
  </TranslationProvider>
);

export default LeadFormRoute;
