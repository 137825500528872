import React, { useState } from "react";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { ChevronDownIcon } from "@wingmate/graphics";
import { Accordion, Flex, Text } from "@wingmate/toolkit";
import { useStore } from "../../../../hooks/useStore";
import { isNullOrUndefined } from "../../../../utils/commons";

import "./NavigationItem.scss";

export interface INavigationItemProps {
  activeIcon?: React.ReactElement;
  children?: React.ReactNode;
  inactiveIcon?: React.ReactElement;
  label: string;
  matchKey?: string;
  to: string;
}

export const NavigationItem = observer(({
  activeIcon,
  children,
  inactiveIcon,
  label,
  matchKey = "",
  to,
}: INavigationItemProps) => {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const { navigationStore } = useStore();
  const { isExpanded, isPinned } = navigationStore;

  const location = useLocation();
  const navigationActiveMatchKey = matchKey || to;

  const iconPresent = !isNullOrUndefined(activeIcon) || !isNullOrUndefined(inactiveIcon);
  const isActive = matchKey ? (
    location.pathname.endsWith(navigationActiveMatchKey)
  ) : (
    location.pathname.startsWith(navigationActiveMatchKey)
  );

  const showLabel = isPinned || isExpanded;
  const showCollapseIcon = showLabel && !isNullOrUndefined(children);

  const navigationLinkClassNames = classNames("NavigationItem__Link", { active: isActive });
  const chevronClassNames = classNames("Link__Chevron", { active: isActive });

  const toggleAccordion = () => {
    setAccordionExpanded(!accordionExpanded);
  };

  return (
    <Accordion
      className="NavigationItem"
      content={<div className="NavigationItem__Children">{accordionExpanded && children}</div>}
      header={(
        <NavLink
          role="link"
          className={navigationLinkClassNames}
          to={to}
        >
          <Flex
            align="center"
            justify="space-between"
            flex="1"
          >
            <Flex align="center" justify="center" gap="large">
              {iconPresent && (
                <div className="Link__Icon">
                  {isActive ? activeIcon : inactiveIcon}
                </div>
              )}
              {showLabel && <Text className="Link__Label" type="P">{label}</Text>}
            </Flex>
          </Flex>
        </NavLink>
      )}
      type="outlined"
      defaultExpanded
      expanded={accordionExpanded}
      expandIcon={(
        <ChevronDownIcon
          className={chevronClassNames}
          data-testid="ChevronDownIcon"
          onClick={toggleAccordion}
        />
      )}
      showArrow={showCollapseIcon}
    />
  );
});

export default NavigationItem;
