import i18next from "i18next";
import locales from "./index";
import commonLocales from "../../common/translations/index";

i18next.init({
  defaultNS: "default",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      default: locales.en,
      common: commonLocales.en,
    },
    es: {
      default: locales.es,
      common: commonLocales.es,
    },
    fr: {
      default: locales.fr,
      common: commonLocales.fr,
    },
    "pt-BR": {
      default: locales.ptBR,
      common: commonLocales.ptBR,
    },
  },
  returnNull: false,
});

export default i18next;
