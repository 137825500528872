import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { authStorePropTypes, rootStorePropTypes } from "../proptypes/stores";
import { withRouter } from "../utils/withRouter";
import { withTranslations } from "../utils/withTranslations";
import { LOGIN_SUCCESS_STATUS } from "../stores/AuthStore";

@withTranslations
@withRouter
@inject("authStore", "rootStore")
@observer
class LoginTokenCheck extends React.Component {
  static propTypes = {
    authStore: authStorePropTypes,
    children: PropTypes.node,
    navigate: PropTypes.func,
    rootStore: rootStorePropTypes,
    searchParams: PropTypes.object,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  submitLogin = async (loginToken) => {
    const { authStore, navigate, rootStore, t } = this.props;
    const { bannerStore } = rootStore;

    const payload = {
      login_token: loginToken,
    };

    const status = await authStore.loginUser(payload);

    if (status === LOGIN_SUCCESS_STATUS) {
      navigate(`/login?first_login_token=${loginToken}`);
    } else {
      bannerStore.addBanner(
        "red",
        t("login.errors.tokenInvalid"),
        t("login.errors.tokenInvalidBody")
      );
    }
  };

  async componentDidMount() {
    const { authStore, searchParams } = this.props;
    const { context } = authStore;
    const { loginToken } = searchParams;

    if (loginToken && !context.currentUser) {
      await this.submitLogin(loginToken);
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading } = this.state;
    const { children } = this.props;
    return <>{!isLoading && children}</>;
  }
}

export { LoginTokenCheck };

export default LoginTokenCheck;
