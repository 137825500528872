import {
  action, makeObservable, observable,
} from "mobx";
import { IChase } from "../types/IChase";
import { IRootStore } from "../types/IRootStore";
import {
  getChases, createChase, deleteChase, updateChase,
} from "../requests/chases";

export class ChasesStore {
  rootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
  }

  @observable chases = [] as IChase[];

  @action
  setChases(chases: IChase[]) {
    this.chases = chases;
  }

  @action
  async getChases(authToken: string, leadId: string, userId: string) {
    const result = await getChases(authToken, leadId, userId);
    this.setChases(result);
  }

  @action
  async createChase(authToken: string, leadId: string, userId: string) {
    const result = await createChase(authToken, leadId, userId);
    this.setChases([...this.chases, result]);
  }

  @action
  async updateChase(authToken: string, chaseId: string, status: string, userId: string) {
    const result = await updateChase(authToken, chaseId, status, userId);
    this.setChases(this.chases.map((chase) => {
      if (chase.id === chaseId) {
        return result;
      }
      return chase;
    }));
  }

  @action
  async deleteChase(authToken: string, chaseId: string, userId: string) {
    await deleteChase(authToken, chaseId, userId);
    this.setChases(this.chases.filter((chase) => chase.id !== chaseId));
  }
}

export default ChasesStore;
