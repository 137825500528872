import React from "react";
import { ScoreCardColumn } from "./ScoreCardColumn";
import PropTypes from "prop-types";

import "./ScoreCardChart.scss";

const TOP_OFFSET = 10;
export class ScoreCardChart extends React.Component {
  static propTypes = {
    performances: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.chartRef = React.createRef();

    this.state = {
      chartHeight: null,
    };
  }

  componentDidMount() {
    this.getChartHeight();
  }

  getChartHeight = () => {
    this.setState({
      chartHeight:
        this.chartRef.current.getBoundingClientRect().height - TOP_OFFSET,
    });
  };

  calculateMaxCount = () => {
    const { performances } = this.props;
    const targetsAndCounts = performances
      .map((performance) => [
        performance.attributes.target,
        performance.attributes.count,
      ])
      .flat();

    const maxCount = Math.max(...targetsAndCounts);

    return maxCount;
  };

  heightForNumber = (number, maxCount) => {
    const { chartHeight } = this.state;

    return (Math.log(number + 1) / Math.log(maxCount + 1)) * chartHeight;
  };

  calculateHeight = (performance) => {
    const maxCount = this.calculateMaxCount();

    const targetHeight = this.heightForNumber(
      performance.attributes.target,
      maxCount
    );

    let progressHeight;
    try {
      const performanceProgress =
        performance.attributes.count / performance.attributes.target;

      if (performanceProgress < 1) {
        progressHeight = parseInt(targetHeight * performanceProgress, 10);
      } else {
        progressHeight = this.heightForNumber(
          performance.attributes.count,
          maxCount
        );
      }
    } catch {
      progressHeight = 0;
    }

    return {
      targetHeight,
      progressHeight,
    };
  };

  renderColumns = () => {
    const { performances } = this.props;

    return performances.map((performance) => {
      const heights = this.calculateHeight(performance);
      return (
        <ScoreCardColumn
          key={`${performance.id}-${performance.attributes.taskId}`}
          performance={performance}
          {...heights}
        />
      );
    });
  };

  render() {
    return (
      <div
        className="ScoreCardChart"
        data-testid="ScoreCardChart"
        data-cy="ScoreCardChart"
        ref={this.chartRef}
      >
        <div className="ScoreCardChart__Lines">
          <div className="ScoreCardChart__Line"></div>
          <div className="ScoreCardChart__Line"></div>
          <div className="ScoreCardChart__Line"></div>
        </div>
        <div className="ScoreCardChart__Columns">{this.renderColumns()}</div>
      </div>
    );
  }
}

export default ScoreCardChart;
