import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { CheckmarkGreenCircleIcon, CrossGreyCircleIcon } from "wm-graphics";
import { Button, FlexCol, FlexRow, Text } from "wm-ui-toolkit";
import { AppContext } from "../../../../../context/AppProvider";
import { rootStorePropTypes } from "../../../../../proptypes/stores";
import { withTranslations } from "../../../../../utils/withTranslations";
import { calculateRoundedPercentage } from "../../../../../utils/commons";
import { PRICE_FIELD_TYPE } from "../../../../../utils/constants";
import { getShortDurationFromDate } from "../../../../../utils/dateTime";
import "./ApprovalItem.scss";

const PURCHASE_TYPE = "Purchase";

@withTranslations
@inject("rootStore")
@observer
export class ApprovalItem extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    approvalRequest: PropTypes.object.isRequired,
    closeNotificationMenu: PropTypes.func,
    rootStore: rootStorePropTypes,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      status: null,
    };
  }

  onApprove = async () => {
    const { approvalRequest, rootStore, t } = this.props;
    const { approvalRequestsStore, bannerStore } = rootStore;
    const { attributes, id } = approvalRequest;
    const { item, itemType, leadId } = attributes;

    try {
      await approvalRequestsStore.sendManagerActionAsync(
        leadId,
        item.id,
        itemType,
        {
          approve: true,
          request: id
        }
      );

      this.setState({ status: "approved" });
    } catch (error) {
      bannerStore.addBanner(
        "red",
        t("common:approval.approvalFailed"),
        t("common:approval.approvalFailedMessage")
      );
    }
  };

  onReject = async () => {
    const { approvalRequest, rootStore, t } = this.props;
    const { approvalRequestsStore, bannerStore } = rootStore;
    const { attributes, id } = approvalRequest;
    const { item, itemType, leadId } = attributes;
  
    try {
      await approvalRequestsStore.sendManagerActionAsync(
        leadId,
        item.id,
        itemType,
        {
          reject: true,
          request: id,
        }
      );

      this.setState({ status: "rejected" });
    } catch (error) {
      bannerStore.addBanner(
        "red",
        t("common:approval.rejectionFailed"),
        t("common:approval.rejectionFailedMessage")
      );
    }
  };

  renderApprovalButtons = () => {
    const { status } = this.state;

    switch (status) {
      case "rejected":
        return <CrossGreyCircleIcon data-testid="rejectedSvg" />;
      case "approved":
        return <CheckmarkGreenCircleIcon data-testid="approvedSvg" />;
      default:
        return (
          <>
            <Button testId="rejectButton" type="text" onClick={this.onReject}>
              <CrossGreyCircleIcon />
            </Button>
            <Button testId="approveButton" type="text" onClick={this.onApprove}>
              <CheckmarkGreenCircleIcon />
            </Button>
          </>
        );
    }
  };

  getFieldName = (fieldPath, customFields) => {
    const fieldPathArray = fieldPath.split(".");

    const fieldObject = customFields.find((field) => field.id === fieldPathArray[fieldPathArray.length - 1]);

    return fieldObject?.name;
  }

  getLeadFieldLabel = (fieldPath) => {
    const { campaign } = this.context;
    const { customFields } = campaign;

    return this.getFieldName(fieldPath, customFields);
  };

  renderLeadBody = () => {
    const { approvalRequest, t } = this.props;
    const { attributes: approvalRequestAttributes } = approvalRequest;
    const { field } = approvalRequestAttributes;

    return (
      <Text type="S">
        {t("common:approval.fieldChanged", {
          field: this.getLeadFieldLabel(field)
        })}
      </Text>
    )
  }

  getPricesheetForItem = () => {
    const { campaign } = this.context;
    const { priceSheets } = campaign;
    const { approvalRequest } = this.props;
    const { attributes } = approvalRequest;
    const { item } = attributes;

    return priceSheets.find((priceSheet) => item.attributes.priceSheetId.toString() === priceSheet.id.toString());
  }

  getPurchaseCustomFieldLabel = (fieldPath) => {
    const pricesheet = this.getPricesheetForItem();
    const { attributes } = pricesheet;
    const { purchaseCustomFields } = attributes;

    return this.getFieldName(fieldPath, purchaseCustomFields);
  };

  renderPurchaseBody = () => {
    const { approvalRequest, t } = this.props;
    const { attributes: approvalRequestAttributes } = approvalRequest;
    const { field, item } = approvalRequestAttributes;
    const { attributes } = item;
    const { price: discountedPrice, purchasable } = attributes;
    const { attributes: purchasableAttributes } = purchasable;
    const { name, price } = purchasableAttributes;

    return (
      <Text type="S">
        {field === PRICE_FIELD_TYPE ? (
          t("common:purchase.priceReduced", {
            name,
            percent: calculateRoundedPercentage(price, discountedPrice),
          })) : (
            t("common:approval.fieldChanged", {
              field: this.getPurchaseCustomFieldLabel(field)
            })
          )
        }
      </Text>
    )
  }

  render() {
    const { approvalRequest, closeNotificationMenu } = this.props;
    const { attributes } =  approvalRequest;
    const { aasmState, itemType, lead, leadId, updatedAt } = attributes;
    const { attributes: leadAttributes } = lead;
    const { identifier } = leadAttributes;

    return (
      <FlexRow
        className="ApprovalItem"
        key={approvalRequest.id}
        gap="XS"
        alignItems="center"
        justifyContent="space-between"
      >
        <Link className="ApprovalItem__link" to={`/leads/${leadId}`} onClick={closeNotificationMenu}>
          <FlexCol className="link__info">
            <Text type="P" weight="Semi">
              {identifier}
            </Text>
            <FlexRow
              className="info__item"
              gap="XXS"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              {itemType == PURCHASE_TYPE ? this.renderPurchaseBody() : this.renderLeadBody()}
              {aasmState !== "open" && (
                <div className="item__date" data-testid="itemDate">
                  <Text type="S">{getShortDurationFromDate(updatedAt)}</Text>
                </div>
              )}
            </FlexRow>
          </FlexCol>
        </Link>
        {aasmState === "open" && (
          <FlexRow
            className="ApprovalItem__actionButtons"
            alignItems="center"
            gap="XXS"
          >
            {this.renderApprovalButtons()}
          </FlexRow>
        )}
      </FlexRow>
    );
  }
}

export default ApprovalItem;
