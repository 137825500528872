import { AxiosError } from "axios";
import { IError, IErrorResponse } from "../types/IError";

interface IDeprecatedErrorResponse {
  errors: string[];
}

interface IDeprecatedErrorMessageResponse {
  message: string;
}

export const getErrorMessage = (error: unknown, defaultMessage?: string): string | undefined => {
  const typedError = error as AxiosError<IDeprecatedErrorResponse>;

  if (typedError?.response?.data?.errors) {
    return Object.values(typedError.response.data.errors).join(" ");
  }

  return defaultMessage;
};

/* Takes in a list of backend serialized errors
* and converts them into a single string.
*/
export const convertErrorsToMessage = (
  errors: IError[],
): string => errors.reduce((messages: string[], error: IError) => {
  const label = error?.attributes?.label;
  const currentErrorMessage = error?.attributes?.message as string;

  messages.push(`${label ? `${label} ` : ""}${currentErrorMessage || ""}`);

  return messages;
}, []).join(". ").trim();

/* Takes in a list of backend serialized errors
* and converts them into a single string.
*/
export const formatAxiosErrorAsMessage = (
  error: unknown,
  defaultMessage?: string,
): string | undefined => {
  const typedError = error as AxiosError<IErrorResponse>;

  const errors = typedError?.response?.data?.errors;

  if (errors && Array.isArray(errors) && errors.length > 0) {
    const message = convertErrorsToMessage(errors);

    return message || defaultMessage;
  }

  return defaultMessage;
};

export const parseErrorMessage = (err: unknown, defaultMessage?: string): string | undefined => {
  const typedError = err as AxiosError<IDeprecatedErrorMessageResponse>;
  const { response } = typedError;

  if (!response) {
    return defaultMessage;
  }

  const { data } = response;

  if (data.message) {
    return data.message;
  }
  return defaultMessage;
};
